<template>
  <div :class="'goodbye' + ( isFlashing ? ' flashing' : '' )" v-on:mousemove="distortions">
    <div class="window">
      <img class="portal" src="../assets/30.jpg" alt="OCULUS ONE" />
    </div>
    <div class="window">
      <img class="view-us" v-on:click="beginRevocation" src="../assets/30.jpg" alt="OCULUS" />
    </div>
    <div class="window phase-2" v-if="hasDrawn">
      <p><a href="https://ocularinitiationphaseii.one/?10">Proceed to the next phase of the becoming.</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodbyeWorld',
  props: {
    msg: String
  },
  data() {
    return {
        music: require("../assets/summonings/revocation.mp3"),
        signal: require("../assets/summonings/signal.mp3"),
        isFlashing: false,
        revocationSequence: 0,
        hasSignalCommmensed: false,
        attendSignal: 0,
        isAttending: false,
        attendanceRecord: 0,
        hasDrawn: false,
        interval: 0,
    }
  },
  methods: {
    distortions(event) {
      const threshold = 25;

      const { clientX, clientY, currentTarget } = event;
      const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;

      const horizontal = (clientX - offsetLeft) / clientWidth;
      const vertical = (clientY - offsetTop) / clientHeight;

      const rotateX = (threshold / 2 - horizontal * threshold).toFixed(2);
      const rotateY = (vertical * threshold - threshold / 2).toFixed(2);

      const windows = Array.from( document.getElementsByClassName("window") );

      for (let i = 0; i < windows.length; i++) {
        requestAnimationFrame(() => {
          windows[i].style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
        } );
      }
    },
    attend() {
      if (!this.hasDrawn) {
        clearTimeout(this.attendSignal)

        this.attendanceRecord++

        if (this.attendanceRecord == this.revocationSequence) {
          this.hasDrawn = true
          console.log("this may not be a wise choice")
        }

        this.attendSignal = setTimeout(() => {
          this.isAttending = false
          this.attendanceRecord = 0
        }, 1000)
      }
    },
    beginRevocation() {
      if (this.revocationSequence === 0) {
        console.log("you have begin revocation 0001000")
        const sound = new Audio(this.music)
        sound.loop = true
        sound.play()

        const signalSound = new Audio(this.signal)

        console.log("you must forge your path")

        let ticks = Math.floor( Math.random() * 10 )

        this.revocationSequence = ticks * 1

        this.interval = setInterval(() => {
          this.isFlashing = false
          signalSound.currentTime = 0
          signalSound.play()

          setTimeout(() => {
            this.isFlashing = true
          }, 100)

          ticks--
          if (ticks == 0) {
            clearInterval(this.interval)
            this.isFlashing = false
            this.hasSignalCommmensed = true
          }
        }, 3500)
      } else {
        if (this.hasSignalCommmensed) {
          this.attend()
        }
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  margin: 0;
  padding: 0;
}

.goodbye{
  display: block;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.goodbye .window{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.goodbye .window img{
  max-height: 100%;
  max-width: 100vw;
}

.goodbye .window .view-us{
  box-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
  border: 2px solid #000;
}

.phase-2{
  width: 100vw;
  height: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.phase-2 a{
  font-family: 'IM Fell English SC', serif;
  font-size: 3rem;
  color: #fff;
  text-decoration: line-through;
  text-align: center;
  margin-top: 1.5rem;
}

.goodbye .portal{
  filter: invert(100%);
}

.flashing .view-us{
  animation: flash 0.5s ease-in-out;
  /* ALL THINGS MUST BE SO IN ORDER FOR REVOCATION */
  animation-iteration-count: 1;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
